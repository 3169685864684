// src/utils/logger.js
import log from 'loglevel';
import axios from 'axios';

log.setLevel('info');

// 创建一个插件，将日志发送到后端
const logServerPlugin = (log) => {
  const originalFactory = log.methodFactory;

  log.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);

    return (...args) => {
      // 发送日志到后端
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/logs`, {
        level: methodName,
        message: args.join(' '),
        timestamp: new Date().toISOString()
      }).catch(error => {
        console.error('发送日志到后端失败:', error);
      });

      rawMethod(...args);
    };
  };

  log.setLevel(log.getLevel()); // 重新设置日志级别以应用插件
};

logServerPlugin(log);

export default log;