<template>
    <div class="exam-detail-page">
      <div class="header">
        <span>考试详情</span>
        <div class="sub-header">
        <small>(红色为作答错误)</small>
      </div>
      </div>
      <div class="content">
        <div class="question">
          <div>
            <span style="font-weight: bold">{{ currentIndex + 1 }}
            . {{ currentQuestion.F_Desc }}</span>{{ getQuestionType(currentQuestion.F_Type) }}({{ currentQuestion.F_Score }}分)
          </div>
          <div class="options">
            <div
              :class="{
                'option-row': !isLongOptions(currentQuestion.F_CandAnswers),
                'option-column': isLongOptions(currentQuestion.F_CandAnswers),
              }"
            >
              <div
                v-for="(option, optIndex) in splitOptions(currentQuestion.F_CandAnswers)"
                :key="optIndex"
                class="option"
                :style="getOptionStyle(currentQuestion, optIndex)"
              >
                {{ String.fromCharCode(65 + optIndex) }}. {{ option }}
              </div>
            </div>
          </div>
        </div>
        <div class="navigation-buttons">
          <button
            class="nav-btn"
            @click="prevQuestion"
            :disabled="currentIndex === 0"
          >
            上一题
          </button>
          <button class="nav-btn" @click="goHome">返回主页</button>
          <button
            class="nav-btn"
            @click="nextQuestion"
            :disabled="currentIndex === questions.length - 1"
          >
            下一题
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        questions: [],
        totalScore: 0,
        currentIndex: 0,
      };
    },
    computed: {
      currentQuestion() {
        return this.questions[this.currentIndex] || {};
      },
    },
    methods: {
      fetchExamDetails() {
        const examId = this.$route.params.id;
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/exam-details/${examId}`;
        fetch(apiUrl, {
          credentials: "include",
        })
          .then((response) => response.json())
          .then((data) => {
            this.questions = data.questions;
            this.totalScore = data.totalScore;
          })
          .catch((error) => {
            console.error("获取考试详情失败:", error);
          });
      },
  
      splitOptions(options) {
        console.log("options:", options);
        return options ? options.split("|") : [];
      },
      isLongOptions(options) {
        return options && options.split("|").some((option) => option.length > 10);
      },
      getOptionStyle(question, optionIndex) {
        const correctAnswers = new Set(question.F_StdAnswers.split(""));
        const userAnswers = new Set(question.F_Answers.split(""));
        const optionChar = String.fromCharCode(65 + optionIndex); // 将索引转换为字母（A, B, C, ...）
  
        if (correctAnswers.has(optionChar)) {
          return { color: "#050afb" }; // 正确答案且用户选择
        }
        if (userAnswers.has(optionChar)) {
          return { color: "red" }; // 用户选择但不正确
        } else {
          return {}; // 默认样式
        }
      },
      prevQuestion() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }
      },
      nextQuestion() {
        if (this.currentIndex < this.questions.length - 1) {
          this.currentIndex++;
        }
      },
      goHome() {
      this.$router.push("/");
    },
    getQuestionType(type) {
      switch (type) {
        case 0:
          return '单选题';
        case 1:
          return '多选题';
        case 2:
          return '判断题';
        default:
          return '未知题型';
      }
    },
    },
    mounted() {
      this.fetchExamDetails();
      document.title = "考试详情"; // 设置页面标题
    },
  };
  </script>
  
  <style scoped src="@/assets/examdetail.css"></style>