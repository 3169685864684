<template>
  <div class="exam-page">
    <button class="back-btn" @click="goBack">返回</button>
    <div class="question-progress">
      {{ currentIndex + 1 }}/{{ questions.length }}
    </div>
    <div v-if="hasData">
      <button class="submit-btn" @click="submitExam">交卷</button>
    </div>

    <div v-if="hasData">
      <div class="header">
        <div class="timer">
          剩余时间: {{ formatTime(minutes) }}:{{ formatTime(seconds) }}
        </div>
      </div>
      <div class="content">
        <div class="question">
          <div>
            <span style="font-weight: bold">{{ currentIndex + 1 }}
            . {{ currentQuestion.F_Desc }} </span>
            {{ getQuestionType(currentQuestion.F_Type) }}({{ currentQuestion.F_Score }}分)

          </div>
          <div class="options">
            <div
              :class="{
                'option-row': !isLongOptions,
                'option-column': isLongOptions,
              }"
            >
              <div
                v-for="(option, index) in splitOptions(
                  currentQuestion.F_CandAnswers
                )"
                :key="index"
                class="option"
              >
                <input
                  v-if="
                    currentQuestion.F_Type === 0 || currentQuestion.F_Type === 2
                  "
                  type="radio"
                  :name="'question' + currentIndex"
                  :value="option"
                  v-model="answers[currentIndex]"
                  :id="'option' + currentIndex + '_' + index"
                />
                <input
                  v-else
                  type="checkbox"
                  :name="'question' + currentIndex"
                  :value="option"
                  v-model="answers[currentIndex]"
                  :id="'option' + currentIndex + '_' + index"
                />
                <label :for="'option' + currentIndex + '_' + index">{{
                  option
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="navigation-buttons">
          <button
            class="nav-btn"
            @click="prevQuestion"
            :disabled="currentIndex === 0"
          >
            上一题
          </button>
          <button
            class="nav-btn"
            @click="nextQuestion"
            :disabled="currentIndex === questions.length - 1"
          >
            下一题
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <p>没有获取到问题数据，请稍后再试。</p>
    </div>
  </div>
</template>
<style scoped>
.swal2-popup {
  font-size: 10px; /* 调整字体大小 */
}
</style>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      currentIndex: 0,
      answers: [],
      questions: [],
      minutes: 60,
      seconds: 0,
      timer: null,
      department: null,
      hasData: false, // 添加标志变量
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex] || {};
    },
    isLongOptions() {
      return (
        this.currentQuestion.F_CandAnswers &&
        this.currentQuestion.F_CandAnswers.split("|").some(
          (option) => option.length > 10
        )
      );
    },
  },
  methods: {
    goBack() {
      if (!this.hasData) {
        // 如果没有考题数据，直接返回
        this.$router.go(-1);
        return;
      }
      Swal.fire({
        title: '确定结束考试?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.go(-1);
        }
      });
    },
    prevQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    nextQuestion() {
      const currentAnswer = this.answers[this.currentIndex];
      if (
        (Array.isArray(currentAnswer) && currentAnswer.length === 0) ||
        (!Array.isArray(currentAnswer) && !currentAnswer)
      ) {
        Swal.fire('请选择答案');
        return;
      }
      if (this.currentIndex < this.questions.length - 1) {
        this.currentIndex++;
      }
    },
    submitExam() {
      // 将答案转换成字母表示法
      const submittedAnswers = this.questions.map((question, index) => {
        const options = this.splitOptions(question.F_CandAnswers);
        const answer = this.answers[index];
        let answerStr = "";

        if (Array.isArray(answer)) {
          // 多选题
          answerStr = answer
            .map((opt) => {
              const optionIndex = options.indexOf(opt);
              return optionIndex !== -1
                ? String.fromCharCode(65 + optionIndex)
                : "-";
            })
            .join("");
        } else {
          // 单选题
          const optionIndex = options.indexOf(answer);
          answerStr =
            optionIndex !== -1 ? String.fromCharCode(65 + optionIndex) : "-";
        }

        return {
          F_ID: question.F_ID,
          F_UnitID: this.department,
          answer: answerStr,
        };
      });

      // 发送到后台
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/submit`;
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submittedAnswers),
        credentials: "include", // 确保请求携带凭证
      })
        .then((response) => response.json())
        .then((data) => {
          Swal.fire(`考试提交成功，总分: ${data.totalScore}`);
          // 跳转到考试详情页面
          this.$router.push(`/exam-details/${data.serial}`);
        })
        .catch((error) => {
          console.error("提交考试失败:", error);
        });

      // 清除计时器
      clearInterval(this.timer);
    },
    updateTimer() {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          Swal.fire('考试时间到！');
          this.submitExam();
          return;
        }
        this.minutes--;
        this.seconds = 59;
      } else {
        this.seconds--;
      }
    },
    formatTime(value) {
      return value < 10 ? "0" + value : value;
    },
    fetchQuestions(department) {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/questions?department=${department}`;
      console.log("[exampage] API URL:", apiUrl); // 添加日志
      fetch(apiUrl, {
        credentials: "include", // 确保请求携带凭证
      })
        .then((response) => {
          console.log("[exampage] Response:", response.status); // 添加日志
          if (response.status === 401) {
            // 未授权，跳转到登录页面
            this.$router.push("/login");
            return;
          } else if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            this.questions = data;
            // 初始化 answers 数组
            if (Array.isArray(this.questions) && data.length > 0) {
              this.answers = this.questions.map((question) =>
                question.F_Type === 1 ? [] : ""
              );
              this.hasData = true; // 有数据
              this.timer = setInterval(this.updateTimer, 1000);
            } else {
              this.hasData = false; // 没有数据
              console.error("获取的问题数据为空或格式不正确:", data);
            }
          } else {
            this.hasData = false; // 请求失败也视为没有数据
            console.error("获取的问题数据为空");
          }
        })
        .catch((error) => {
          console.error("获取问题失败:", error);
        });
    },
    getQuestionType(type) {
      switch (type) {
        case 0:
          return '单选题';
        case 1:
          return '多选题';
        case 2:
          return '判断题';
        default:
          return '未知题型';
      }
    },
    splitOptions(options) {
      //console.log('Splitting options:', options);
      return options ? options.split("|") : [];
    },
  },
  mounted() {
    this.department = this.$route.params.department;
    console.log("Department:", this.department); // 打印部门信息

    this.fetchQuestions(this.department);
    document.title = "考试"; // 设置页面标题
  },
  beforeUnmount() {
    // 清除计时器
    clearInterval(this.timer);
  },
};
</script>

<style scoped src="@/assets/styles.css"></style>
