import { createRouter, createWebHistory } from 'vue-router';
import log from '@/utils/logger'; // 引入日志模块
//import HomePage from '@/components/HomePage.vue';
import HomePage from '@/components/HomePage.vue';
import RulePage from '@/components/ExamRulePage.vue';
import ExamPage from '@/components/ExamPage.vue';
import RecordsPage from '@/components/ExamRecordsPage.vue';
import ExamDetailPage from '@/components/ExamDetailPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/rule/:department?',
    name: 'Rule',
    component: RulePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/exam/:department?',
    name: 'Exam',
    component: ExamPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/records',
    name: 'Records',
    component: RecordsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/exam-details/:id',
    name: 'ExamDetail',
    component: ExamDetailPage,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

function checkSession() {
  const apiUrl = process.env.VUE_APP_API_BASE_URL;
  log.info(`[checkSession] Checking session with API URL: ${apiUrl}`);
  return fetch(`${apiUrl}/api/check-session`, {
    credentials: 'include' // 确保请求携带凭证
  })
    .then(response => response.json())
    .then(data => {
      if (!data.loggedIn) {
        log.info('[checkSession] User not logged in, clearing localStorage and returning false');
        // 如果后端返回未登录状态，清除 localStorage 并重定向到登录页面
        localStorage.removeItem('user');
        return false;
      } else {
        log.info('[checkSession] User is logged in:'+data.user);
        // 如果后端返回已登录状态，可以继续进行后续操作
        console.log('用户已登录:', data.user);
        return true;
      }
    })
    .catch(error => {
      log.error('[checkSession] Failed to check session:'+error);
      console.error('检查会话状态失败:', error);
      // 处理错误情况，例如显示错误消息或重定向到错误页面
      return false;
    });
}

// 导航守卫
router.beforeEach(async (to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('user'); // 假设登录信息存储在localStorage中
  console.log('[index.js] isLoggedIn:', isLoggedIn);
  console.log('[index.js] to:', to);
  log.info('[router.beforeEach] isLoggedIn:'+isLoggedIn);
  log.info('[router.beforeEach] Navigating to:'+to.path);

  if (!isLoggedIn) {
    // 如果用户未登录，跳转到微信登录页面
    log.info('[router.beforeEach] User not logged in, redirecting to login page');
    window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/wechat/login`;
    return;
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果访问需要授权的页面，检查会话状态
    const sessionValid = await checkSession();
    if (sessionValid) {
      next();
    } else {
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/wechat/login`;
    }
  } else {
    next();
  }
});

export default router;