<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import log from '@/utils/logger'; // 引入日志模块
export default {
  created() {
    log.info('App.vue created hook called');

    // 检查是否已经登录
    if (!this.isLoggedIn()) {
      log.info('User not logged in, redirecting to WeChat login');
      // 如果没有登录，重定向到微信授权页面
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/wechat/login`;
    }
    else {
      log.info('User already logged in');
    }
  },
  methods: {
    isLoggedIn() {
      const isLoggedIn = !!localStorage.getItem('user');
      log.info(`isLoggedIn method called, result: ${isLoggedIn}`);
      // 检查用户是否已经登录的逻辑，例如检查本地存储或 Vuex 状态
      return isLoggedIn;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
