<template>
  <div class="exam-records-page">
    <button class="back-btn" @click="goBack">返回</button>
    <h1 class="title">考试记录</h1>
    <div class="records-container">
      <div class="record" v-for="(record) in records" :key="record.F_ID">
        <div class="record-info">
          <div class="record-row">
            <div>单位: {{ record.F_UnitName }}</div>
            <div>
              <span :class="{'pass': record.F_TotalScore > 70, 'fail': record.F_TotalScore <= 70}">
                {{ record.F_TotalScore > 70 ? '及格' : '不及格' }}
              </span>
              得分: {{ record.F_TotalScore }}分
              
            </div>
          </div>
          <div class="record-row">
            <div>考试时间: {{formatDate(record.F_CreateDate) }}</div>
            <button class="view-btn" @click="viewDetail(record.F_Serial)">查看详情</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      records: [],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    viewDetail(id) {
      this.$router.push(`/exam-details/${id}`);
    },
    fetchRecords() {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/api/records`;
      console.log("API URL:", apiUrl);
      fetch(apiUrl, {
        method: "GET",
        credentials: 'include'
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("[examrecordspage] Response data:", data);
          this.records = data;
        })
        .catch((error) => {
          console.error("[examrecordspage] 获取考试记录失败:", error);
        });
    },
    formatDate(dateString) {
      const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false // 24小时制，不显示上午/下午
      };
      return new Date(dateString).toLocaleString('zh-CN', options);
    }
  },
  mounted() {
    this.fetchRecords();
  },
};
</script>

<style scoped src="@/assets/examrecord.css"></style>